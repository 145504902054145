import type { ImageCTX, ProviderGetImage } from '@nuxt/image'

const assets = import.meta.glob<string>(
  [
    '~/assets/**/*.apng',
    '~/assets/**/*.bmp',
    '~/assets/**/*.png',
    '~/assets/**/*.jpg',
    '~/assets/**/*.jpeg',
    '~/assets/**/*.jfif',
    '~/assets/**/*.pjpeg',
    '~/assets/**/*.pjp',
    '~/assets/**/*.gif',
    '~/assets/**/*.ico',
    '~/assets/**/*.webp',
    '~/assets/**/*.avif',
  ],
  { eager: true, import: 'default' },
)

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, ...options } = {},
  { $img } = { $img: useImage() } as ImageCTX,
) => {
  if (src.startsWith('~/assets/'))
    return { url: assets[src] ?? src }

  if (src.startsWith('//'))
    src = `https:${src}`

  let provider = 'none'

  if (/^https?:\/\//.test(src)) {
    const url = new URL(src)

    if (/(?:^|\.)hdslb\.com$/.test(url.host)) {
      provider = 'bilibili'
    }
    else {
      return { url: src }
    }
  }
  else {
    provider = 'aliyun'
  }

  return {
    url: $img!(src, modifiers, { preset: 'default', ...options, provider }),
  }
}
