import type { ProviderGetImage } from '@nuxt/image'
import { createOperationsGenerator } from '#image'

const operationsGenerator = createOperationsGenerator({
  formatter: (key: string, value: string) => `${value}${key[0]}`,
  joinWith: '_',
})

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {} } = {},
) => {
  let { format = 'webp', fit = '', ..._modifiers } = modifiers

  _modifiers = useMapKeys(_modifiers, (_: never, key: string) => key[0])
  Object.assign(
    _modifiers,
    {
      cover: { c: 1 },
      contain: { e: 0 },
      fill: { e: 2 },
      inside: { e: 0 },
      outside: { e: 1 },
    }[fit],
  )

  const [_src, _params] = src.split('@')
  if (_params) {
    src = _src!
    const [_operations, _format] = _params.split('.')

    format = _format ?? format
    Object.assign(
      _modifiers,
      useFromPairs(
        useFilter(
          _operations
            ?.split('_')
            .map(op => /^(\d+)(\w)/.exec(op)?.slice(1, 3).reverse()),
        ) as [string, string][],
      ),
    )
  }

  return {
    url: `${src}@${operationsGenerator(_modifiers)}.${format}`,
  }
}
